// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

import $ from 'jquery';
global.$ = jQuery;

import 'bootstrap'
import 'jquery-mask-plugin'
import 'lazysizes'

require('packs/yt-lazyload.min');

Rails.start()
Turbolinks.start()
ActiveStorage.start()

var SPMaskBehavior = function (val) {
  return val.replace(/\D/g, '').length === 11 ? '(00) 00000-0000' : '(00) 0000-00009';
},
spOptions = {
  onKeyPress: function(val, e, field, options) {
      field.mask(SPMaskBehavior.apply({}, arguments), options);
    }
};


$( document ).on('turbolinks:load', function() {
	$(".dashboard-submenu").on('scroll', function() {
    let dash_menu_scroll = $(this).scrollLeft();

    if(Math.round($(this).scrollLeft() + $(this).innerWidth()) >= $(this)[0].scrollWidth){
        $(".nav-next").hide();
      } else {
      $(".nav-next").show();
    }

    if(dash_menu_scroll == 0){
      $(".nav-prev").hide();
    } else {
      $(".nav-prev").show();
    }
  });

  $(".nav-next").on("click", function(){
    $(".dashboard-submenu").animate( { scrollLeft: '+=460' }, 200);
  });

  $(".nav-prev").on("click", function(){
    $(".dashboard-submenu").animate( { scrollLeft: '-=460' }, 200);
  });

  $('.cellphone').mask(SPMaskBehavior, spOptions);
  $('#subscription_card_number').mask('0000 0000 0000 0000 0000');
  $('#subscription_cvv').mask('0000');

  $('.open-menu').on('click', function () {
    $('.navbar-menu').toggleClass('active');
    $('body').toggleClass('active');
    $('.home-header').toggleClass('active');
  });

  $('.login-with-email').on('click', function () {
    $('.login-with-email').toggleClass('active');
    $('.form-login').toggleClass('active');
  });

  if ((typeof has_erros !== 'undefined') && (has_erros == true)) {
    $('.login-with-email').addClass('active');
    $('.form-login').addClass('active');
  }

  // courses page
  $(window).bind('scroll', function() {
    var navHeight = $( window ).height() - 70;

    if ($(window).scrollTop() > navHeight) {
      $('.course-highlight').addClass('active');
    }
    else {
      $('.course-highlight').removeClass('active');
    }
  });

  $(document).on('click', 'a[href^="#"]', function (event) {
    event.preventDefault();
    if($('.course-highlight').hasClass('active')) {
      $('html, body').animate({
          scrollTop: $($.attr(this, 'href')).offset().top - 110
      }, 500);
    } else {
      $('html, body').animate({
        scrollTop: $($.attr(this, 'href')).offset().top - 160
    }, 500);
    }
  });
  // courses page

  function copyToClipboard(element) {
    var $temp = $("<input>");
    $("body").append($temp);
    $temp.val($(element).text()).select();
    document.execCommand("copy");
    $temp.remove();
  }

  $('.js-copy-btn').on('click', function(event) {
    copyToClipboard('#barcode');
    $(this).text('Copiado!')
  });
});

document.addEventListener('turbolinks:before-visit', function(e) {
  var parser = document.createElement('a');
  parser.href = e.data.url;

  if(parser.host == window.location.host && parser.pathname == window.location.pathname) {
    e.preventDefault();
    window.location.href = e.data.url;
  }
});
